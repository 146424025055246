import * as React from 'react';
// import { Link } from "gatsby"
import {StaticImage} from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import Button from '../../button/button';
import PDFDownload from '../../../../static/deluxeboard_colour_options.pdf';

const Outer = styled.section`
  .imagesIpad {
    display: none;
  }

  .imagesDesktop {
    display: none;
  }

  margin: 64px 0;

  .inner {
    max-width: var(--site-width);
    margin: 0 auto;
    // display: grid;
    justify-content: center;
  }

  .innerTitle {
    text-align: center;
    color: var(--grey);
    margin-bottom: 0;

  }

  .imageTitles {
    font-size: 1.3rem;
    color: var(--grey);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;

  }

  .innerText {
    text-align: center;
  }

  .innerButton {
    text-align: center;
  }

  @media (min-width: 370px) {
    .imagesMobile {
      display: none;
    }

    .imagesIpad {
      display: grid;
    }
  }
  @media (min-width: 778px) {
    .imagesIpad {
      display: none;
    }

    .imagesDesktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: start;
    }
  }
`;

const ColoursMobile = () => (
    <Outer>
		<div className='inner'>
			<h2 className='innerTitle'>colours</h2>
			<p style={{
        textAlign: `center`,
        fontSize: `16px`,
        marginTop: `0`,
        paddingTop: `0`,
      }}><em>note: not all colours are displayed on mobile.<br />download the PDF below for our full colour chart.</em></p>

      {/* MOBILE START */}
      <div className='imagesMobile'>
				<div className=''>
					<p className='imageTitles'>Standard Colours SMP Pre-painted steel</p>
					<StaticImage
              src='phone_smp.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='mobile color palette smp'
          />
				</div>
				<div className=''>
					<p className='imageTitles'>wood grain pvdf pre-painted steel</p>
					<StaticImage
              src='phone_wood_pvdf.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='mobile colour palette wood pvdf'
          />
				</div>
				<div className=''>
					<p className='imageTitles'>premium colours pvdf pre-painted steel</p>
					<StaticImage
              src='phone_premium_pvdf.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='mobile colour palette premium pvdf'
          />
				</div>
				<div className=''>
					<p className='imageTitles'>tex smp pre-painted steel</p>
					<StaticImage
              src='phone_tex_smp.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='mobile colour palette tex pvd'
          />
				</div>
			</div>
      {/* MOBILE END */}

      {/*  IPAD START */}
      <div className='imagesIpad'>
				<div className=''>
					<p className='imageTitles'>Standard Colours SMP pre-painted steel</p>
					<StaticImage
              src='tab_smp.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='ipad color palette smp'
          />
				</div>
				<div className=''>
					<p className='imageTitles'>wood-grain pvdf pre-painted steel</p>
					<StaticImage
              src='tab_wood_pvdf.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='ipad colour palette wood pvdf'
          />
				</div>
				<div className=''>
					<p className='imageTitles'>premium colours pvdf  pre-painted steel</p>
					<StaticImage
              src='tab_premium_pvdf.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='ipad colour palette premium pvdf'
          />
				</div>
				<div className=''>
					<p className='imageTitles'>tex smp pre-painted steel</p>
					<StaticImage
              src='tab_tex_smp.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='ipad colour palette tex pvd'
          />
				</div>
			</div>
      {/* IPAD END */}


      {/*  DESKTOP START */}
      <div className='imagesDesktop'>
				<div className=''>
					<p className='imageTitles'>Standard Colours SMP pre-painted steel</p>
					<StaticImage
              src='desk_smp.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='desktop color palette smp'
          />
				</div>
				<div className=''>
					<p className='imageTitles'>wood-grain pvdf pre-painted steel</p>
					<StaticImage
              src='desk_wood_pvdf.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='desktop colour palette wood pvdf'
          />
					<p className='imageTitles'>premium colours pvdf pre-painted steel</p>
					<StaticImage
              src='desk_premium_pvdf.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='desktop colour palette premium pvdf'
          />
					<p className='imageTitles'>tex smp pre-painted steel</p>
					<StaticImage
              src='desk_tex_smp.jpg'
              quality={100}
              layout='fullWidth'
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt='desktop colour palette tex pvd'
          />
				</div>
			</div>
      {/* DESKTOP END */}

      <p className='innerText' style={{
        textAlign: `center`,
        fontSize: `16px`,
        marginTop: `32px`,
        paddingTop: `0`,
      }}><em>Colours on paint and painted batches may vary from separate production batches. Over time, boards will weather resulting in variations if panels are added at later dates. The colours represented in this booklet/website are print approximations and may vary from the actual product.</em></p>
			<p className="innerButton"><a href={PDFDownload}
                                    title="download siding colour chart"><Button
          cta="Catalogue (PDF)" /></a></p>
		</div>
	</Outer>
);

export default ColoursMobile;