import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import BgImage from "./faded_background.jpg"

const Outer = styled.section`
background: url(${BgImage});
background-repeat: no-repeat;
background-position: center;
background-size: cover;


h2 {
	color: var(--grey);
	text-decoration: underline;
	text-decoration-color: var(--blue);
	text-underline-offset: 3px;
	text-align: center;
}

.inner {
	max-width: var(--site-width);
	margin: 0 auto;
	padding: 64px 0;
}

.imagesBox {
	width: 170px;
//   height: 215px;
height: auto;
border-bottom: 2px solid var(--blue);
margin-bottom: 16px;
}

.pTitles {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 8px;
	margin-top: 4px;
	text-align: center;
}

.imagesContainer {
	display: grid;
	justify-content: center;
}

@media (min-width:500px) {
	.imagesContainer {
		grid-template-columns: 1fr 1fr;
		justify-items: center;
	}
}

@media (min-width: 768px) {
	.imagesContainer {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (min-width: 1000px) {
	.imagesContainer {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	
	.imagesBox {
		border: none;

	}
}



`

const PanelProfilesPart = () => (
	<Outer>
		<div className="inner">
			<h2>soffit &amp; panel profiles</h2>

			<div>
				<div className="imagesContainer">
					<div className="imagesBox">
						<div>
							<StaticImage
								src="vented_soffit.jpg"
								// aspectRatio={0.79 / 1}
								width={170}
								height={215}
								quality={95}
								formats={["AUTO", "WEBP", "AVIF"]}
								alt="vented soffit panel"
							/>
							<p className="pTitles">vented soffit</p>
						</div>
					</div>
					<div className="imagesBox">
						<StaticImage
							src="solid_soffit.jpg"
							// aspectRatio={0.79 / 1}
							width={170}
							height={215}
							quality={95}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="solid soffit panel"
						/>
						<p className="pTitles">solid soffit</p>
					</div>
					<div className="imagesBox">
						<StaticImage
							src="vertical_reveal_panel.jpg"
							// aspectRatio={0.79 / 1}
							width={170}
							height={215}
							quality={95}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="vertical reveal panel"
						/>
						<p className="pTitles">vertical reveal panel</p>
					</div>
					<div className="imagesBox">
						<StaticImage
							src="deluxe_vertical_batten.jpg"
							// aspectRatio={0.79 / 1}
							width={170}
							height={215}
							quality={95}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="deluxe vertical batten panel"
						/>
						<p className="pTitles">deluxe vertical batten</p>
					</div>
					<div className="imagesBox">
						<StaticImage
							src="flush_panel.jpg"
							// aspectRatio={0.79 / 1}
							width={170}
							height={215}
							quality={95}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="flush panel panel"
						/>
						<p className="pTitles">flush panel</p>
					</div>
				</div>
			</div>
		</div>
	</Outer>
)

export default PanelProfilesPart