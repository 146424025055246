import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Button from "../../button/button"

const Outer = styled.section`
margin: 64px auto 0 auto;

.inner {
max-width: var(--site-width);
margin: 0 auto;

display: grid;
grid-template-colunns: 1fr;
justify-content: center;

h2 {
	color: var(--grey);
	text-decoration: underline;
	text-decoration-color: var(--blue);
	text-underline-offset: 3px;
	}
}

@media(min-width: 1024px) {
	.inner {
	grid-template-columns: 1fr 1fr;
	margin: 64px auto;
	}

	.alignCenter {
		align-self: center;
	}
}
`

const StyledA = styled.a`
margin-left: 16px;
`

const GotYouCovered = () => (
	<Outer>
		<div className="inner">
			<div>
				<h2>we've got you covered</h2>
				<p>Motivated to be industry-leading innovators who provide a better product with first-rate customer service, our team decided to turn our passion into reality at a more competitive rate than the competition. Our networks with suppliers, engineers, retailers, architects, and installers work collaboratively to ensure a high-quality, extremely durable, affordable, made-to-order result for all our products. Commercial or residential, our metal cladding has you covered with the look you want.</p>
				<StyledA href="tel:+16045726799" title="call 1-604-572-6799"><Button cta="Call Us" /></StyledA>
			</div>
			<div className="alignCenter">
				<StaticImage
					src="house_siding.jpg"
					aspectRatio={16 / 9}
					layout="fullWidth"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="house siding demo homepage 1"
				/>
			</div>
		</div>
	</Outer>
)

export default GotYouCovered