import * as React from "react"
import styled from "@emotion/styled"
import BgImage from "./faded_background.jpg"

const Outer = styled.section`
background: url(${BgImage});
background-repeat: no-repeat;
background-position: center;
background-size: cover

h2 {
	color: var(--grey);
	text-decoration: underline;
	text-decoration-color: var(--blue);
	text-underline-offset: 3px;
	text-align: center;
}

.inner {
	max-width: var(--site-width);
	margin: 0 auto;
	padding: 64px 0;
}

.GridAllText {
	display: grid;
	justify-content: center;
	align-items: center;
}
`

const OurCommPart = () => (
	<Outer>
		<div className="inner">

			<div className="GridAllText">
				<p><strong>Our Commitment to Customer Service</strong><br />
					The Deluxe Board sales team has your best interests at heart.  Customer service is our number one priority.  We first showcase our continually growing range of swatches and shapes to find the look you desire.  Then we crunch numbers to provide you the best solution for your budget.  From start to finish, we've got you covered!</p>
				<p><strong>Our Commitment to the Environment</strong><br />
					Deluxe Board is a 100% recyclable product that not only provides long-term durability to protect your building, but our production process works to protect the environment.  When you finish your building's soffit and siding with Deluxe Board cladding, you know that you are doing your part to build a sustainable society.</p>
				<p><strong>Our Commitment to Quality</strong><br />
					We combine cutting-edge technologies and a fashionable eye for appearances, emphasizing traditional master-quality metalwork craftsmanship to manufacture the best cladding on the market. Soffit or siding, whatever       products you need, we've got you covered with our Deluxe Board guarantee for quality.</p>
			</div>
		</div>
	</Outer >
)

export default OurCommPart
