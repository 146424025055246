import * as React from "react"
// import { Link } from "gatsby"
// import Button from "../buttons/button.js"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

const HeroText = styled.div`
  display: grid;

  justify-content: center;
  align-items: center;

  margin-top: 32px;

  .image {
    margin: 0 auto;
  }

  .text {

    h1, p {
      text-align: center;
      color: white;
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
      @media (min-width: 768px) {
        font-size: 3rem;
      }
      @media (min-width: 1200px) {
        font-size: 4rem;
      }


    }

    .col {
      margin-bottom: 50px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  @media (min-width: 768px) {
    h1 {
      margin-top: 0;
    }

    .pText {
      @media (min-width: 1200px) {
        font-size: 27px;
      }
    }
  }

`

const HomeHero = () => (
  <div style={{ display: "grid" }}>
    {/* You can use a GatsbyImage component if the image is dynamic */}
    <StaticImage
      style={{
        gridArea: "1/1",
        // You can set a maximum height for the image, if you wish.
        maxHeight: 615
      }}
      layout="fullWidth"
      // You can optionally force an aspect ratio for the generated image
      aspectRatio={16 / 9}
      objectFit="center"
      // This is a presentational image, so the alt should be an empty string
      alt=""
      src={
        "../../components/heros/homeHeroBackground.jpg"
      }
      formats={["auto", "webp", "avif"]}
      loading="eager"
      placeholder="tracedSVG"
    />

    <div
      style={{
        // By using the same grid area for both, they are stacked on top of each other
        gridArea: "1/1",
        position: "relative",
        // This centers the other elements inside the hero component
        placeItems: "center",
        display: "grid"
      }}
    >
      {/* Any content here will be centered in the component */}
      <HeroText>
        <div className="image">
          <StaticImage
            src="../../components/heros/homeHeroIcon.png"
            width={84}
            height={62}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="diamond placeholder"
            placeholder="tracedSVG"
          />
        </div>
        <div className="text">
          <p className="pText">
            DELUXE BOARDS ARCHITECTURAL PRODUCTS
          </p>
          <h1>Metal Cladding &amp; Siding</h1>
        </div>
      </HeroText>
    </div>
  </div>
)

export default HomeHero