import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import BgImage from "./faded_background.jpg"

const Outer = styled.section`
display: none;

@media(min-width: 1200px) {
	display: block;
	background: url(${BgImage});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 100%;
	position: relative;
	
	.inner {
		max-width: var(--site-width);
		margin: 0 auto;
		padding: 64px 0;


		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 1fr 1fr;
		gap: 40px;
		justify-content: center;
		align-items: start;
		grid-template-areas: "objectOne objectOne" "objectTwo objectThree";

		h2 {
			color: var(--grey);
			text-decoration: underline;
			text-decoration-color: var(--blue);
			text-underline-offset: 3px;
		}

		.objectOne {
			grid-area: objectOne;
			text-align: center;
			
		}
		.objectTwo {
			grid-area: objectTwo;
		}
		.objectThree {
			grid-area: objectThree;
		}
	}
}
`

const PanelsAndTrimDesktop = () => (
	<Outer>
		<div className="inner">
			<div className="objectOne">
				<h2>panels &amp; trim</h2>
			</div>
			<div className="image objectTwo">
				<StaticImage
					src="desktop_1.png"
					// aspectRatio={16 / 9}
					layout="fullWidth"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="cladding desktop image one"
				/>
			</div>
			<div className="image objectThree">
				<StaticImage
					src="desktop_2.png"
					// aspectRatio={16 / 9}
					layout="fullWidth"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="cladding desktop image two"
				/>
			</div>
		</div>
	</Outer>
)

export default PanelsAndTrimDesktop