import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

const Outer = styled.section`
margin: 64px 0;

h2 {
	color: var(--grey);
	text-decoration: underline;
	text-decoration-color: var(--blue);
	text-underline-offset: 3px;
	text-align: center;
}

.inner {
	max-width: var(--site-width);
	margin: 0 auto;
}

.fourGrid {
	display: grid;
	grid-template-columns: minmax(360px, 500px);
	justify-content: center;
}

@media(min-width: 1000px) {
	.fourGrid {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 32px;
		align-items: center;
	}

	.boxD {
		grid-row: 2;
		grid-column: 1;

	}
}

`

const MaintStorePart = () => (
	<Outer>
		<div className="inner">
			<h2>maintenance &amp; storage</h2>

			{/* container for row using grid for placement */}
			<div className="fourGrid">

				{/* row 1  textLeft */}
				<div className="boxSize boxA">
					<p style={{ fontWeight: `bold` }}>
						Our Commitment to Customer Education for Proper Care &amp; Handling
					</p>
					<p>
						We know how important it is to ensure both our retail partners and our end-use customers know the proper way to store, move, and maintain our product:
					</p>
					<p>
						When storing cladding before installation, it is crucial to keep moisture and pooled water off the product to prevent paint damage.  Plastic fails as a cover because it obstructs proper airflow; breathable material covers atop cladding propped up off the ground would be more suitable.  If bundles get wet, immediately separate and dry the sheets individually.  If possible, keep cladding off the ground and in a dry area for best results. Corrosive materials must not come into contact with cladding and should be stored separately. Ensure that material does not get stacked, this might damage, and leave indentations on it.
					</p>
				</div>
				{/* row 1 imageRight */}
				<div className="boxSize boxB">
					<div>
						<StaticImage
							src="siding_closeup.jpg"
							aspectRatio={1.15 / 1}
							// width={600}
							// height={520}
							quality={100}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="siding closeup placeholder A"
						/>
					</div>
				</div>
				{/* row 2 textRight */}
				<div className="boxSize boxC">
					<p>Transporting cladding in our rainy coastal climate means that tarping is necessary if  there is any possibility of wet weather.</p>
					<p>Proper upkeep begins from the moment installation is complete by performing a detailed cleanup of all scraps and debris from all Deluxe Boards.  As part of your          building's Spring-cleaning regime, it is essential to hose down the finished exterior.  Avoid chemical cleaners without first checking instructions to ensure your finished surface is not damaged; mineral spirits applied to and washed off from soiled spots are all you need to use.  Surrounding trees and seasonal wind will determine the frequency of inspections and cleaning required to remove leaves and check for damage to the exterior coat.</p>
					<p>Basic, routine caretaking will continue to breathe long life into your Deluxe Boards.</p>
				</div>

				{/* row 2 imageLeft */}
				<div className="boxSize boxD">
					<div>
						<StaticImage
							src="siding_building.jpg"
							aspectRatio={1.15 / 1}
							// width={600}
							// height={520}
							quality={100}
							formats={["AUTO", "WEBP", "AVIF"]}
							alt="siding closeup placeholder B"
						/>
					</div>
				</div>

				{/* grid ends on next line */}
			</div>
		</div>
	</Outer >
)

export default MaintStorePart
