import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import styled from "@emotion/styled"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeHero from "../components/heros/homeHero"

// parts
import GotYouCovered from "../components/home/gotYouCovered/gotYouCovered"
import PanelsAndTrimMobile from "../components/home/panelsAndTrim/panelsAndTrimMobile"
import PanelsAndTrimDesktop from "../components/home/panelsAndTrim/panelsAndTrimDesktop"
import ColoursMobile from "../components/home/colours/colours"
import PanelProfilesPart from "../components/home/panelProfiles/panelProfiles"
import MaintStorePart from "../components/home/maintStore/maintStore"
import OurCommPart from "../components/home/ourCommitment/ourCommitment"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" description="Metal Cladding and Siding Products" canonical="https://deluxeboard.ca/" />
    <HomeHero />

    <GotYouCovered />

    <PanelsAndTrimMobile />
    <PanelsAndTrimDesktop />

    <ColoursMobile />


    <PanelProfilesPart />

    <MaintStorePart />

    <OurCommPart />

  </Layout>
)

export default IndexPage
