import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import BgImage from "./faded_background.jpg"

const Outer = styled.section`
margin: 0 auto;
padding: 64px 0;
background: url(${BgImage});
background-repeat: no-repeat;
background-position: center;
background-size: cover;

.inner {
	max-width: var(--site-width);
	margin: 0 auto;

	display: grid;
	grid-template-colunns: 1fr;
	gap: 40px;
	justify-content: center;
	align-items: center;

	h2 {
		color: var(--grey);
		text-decoration: underline;
		text-decoration-color: var(--blue);
		text-align: center;
		text-underline-offset: 3px;
	}
}

.image {
}

@media(min-width: 768px) {
  .inner {
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	align-items: start;
	grid-template-areas: "objectOne objectOne" "objectTwo objectThree";

	.objectOne {
		grid-area: objectOne;
		
	}
	.objectTwo {
		grid-area: objectTwo;
	}
	.objectThree {
		grid-area: objectThree;
	}
  }
}

@media(min-width: 1200px) {
	display: none;
}
`

const PanelsAndTrimMobile = () => (
	<Outer>
		<div className="inner">
			<div className="objectOne">
				<h2>panels &amp; trim</h2>
			</div>
			<div className="image objectTwo">
				<StaticImage
					src="mobile_1.png"
					layout="fullWidth"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="cladding mobile image one"
				/>
			</div>
			<div className="image objectThree">
				<StaticImage
					src="mobile_2.png"
					layout="fullWidth"
					quality={100}
					formats={["AUTO", "WEBP", "AVIF"]}
					alt="cladding mobile image two"
				/>
			</div>
		</div>
	</Outer>
)

export default PanelsAndTrimMobile